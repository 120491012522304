.NavBar{
    background-color: #1C1C1C;
}
.OrderCard{
    margin-top: 15px;
    margin-bottom: 15px;
}
.orderHistoryInfo{
    margin-left: 15px;
}
.Mapservice{
    text-align: center;
}