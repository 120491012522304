.NaviBar{
    background-color: #1C1C1C;
}
.tips{
    margin-top: 15px;
    margin-bottom: 15px;
}
.tipList{
    margin-left: 20px;
    margin-right: 10px;
}