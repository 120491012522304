.NaviBar{
    background-color: #1C1C1C;
}

.ReturnButton{
    color: white;
    margin-top: 5px;
}

.loginCard{
     margin-top: 15px;
 }
.LoginButton{
    margin-top: 15px;
}