.NaviBar{
    background-color: #1C1C1C;
}
.card{
    margin-top: 10px;
    margin-bottom: 10px;
}
.cardHeader{
    font-size: 20px;
}
.shippingNote{
    color: gray;
}
.dicountInfo{
    color: gray;
}