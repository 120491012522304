.NaviBar{
    background-color: #1C1C1C;
}
.resultIMG{
    width: 60px;
    height: 60px;
}

.ProductImage{
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1%;
    width: 90%;
    height: 180px;
    object-fit: cover;
}
.ProductDetailPurchaseDetail{
    font-size: 15px;
}
.Stepper{
    width:900px;
}
.CartButton{
    margin-top: 20px;
}
.ProductCard{
    margin-top: 20px;
}
.DetailList{
    margin-top: 10px;
    margin-bottom: 10px;
}
.PurchaseButton{
    margin-top: 20px;
}
.ErrorReturnButton{
    margin-top: 15px;
}