.ProductIMG{
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1%;
    width: 80%;
    height: 150px;
    object-fit: cover;
}
.returnButton{
    color: white;
}

.PriceRange{
    margin-left: 6px;
    margin-right: 6px;
}

.productFilterDrawer{
    position: relative;
    overflow: auto;
    background-color: #fff;
    -webkit-overflow-scrolling: touch;
    width: 300px;
    padding: 0;

}
.FilterButtons{
    margin-left: 10px;
    margin-bottom: 10px;
}
.FilterConfirmButton{
    width: 110px;
    margin-right: 10px;
}
.FilterResetButton{
    width: 110px;
}

.NaviBar{
    background-color: #1C1C1C;
}
.ProductName{
    margin-top: 10px;
    margin-bottom: 10px;
}
.ProductDivider{
    margin-top: 5px;
    margin-bottom: 5px;
}
.ProductPriceButton{
    height: 40px;
    margin-right: 15%;
    margin-left: 15%;
    margin-bottom: 5px;
    width: 70%;
}
.ProductPrice{
    font-size: 1.5em;
}
.PageArrow{
    display: flex;
    align-items: center;
}
.Pagination{
    margin: 0 15px;
    margin-top: 10px;
    margin-bottom: 15px;
}
.PageEndDivider{
    margin-top: 15px;
    margin-bottom: 5px;
}

.ProductCard{
    animation-duration: 1s;
    animation-name: sli;
}
.Disclaimer{
    margin-top: 20px;
}

.SearchBar{
    margin-top: 5px;
    margin-bottom: -30px;
    background-color: rgba(0,0,0,0);
}