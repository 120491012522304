.ProgessBoard{
    margin-top: 15px;
}
.progressDashboard{
    text-align: center;
}
.accountProgressList{
    margin-top: 15px;
}
.informationCard{
    margin-top: 10px;
    margin-bottom: 10px;
}
.healthButton{
    margin-bottom: 10px;
}
.ErrorInfo{
    margin-top: 15px;
}
.ErrorAccount{
     background-color: #1C1C1C;
}