.NavgationBar{
    background-color: #1C1C1C;
}

.InfoCard{
    margin-top: 10px;
}

.RegisterButton{
    margin-top: 20px;
}