.RemoveButton{
    margin-bottom: 4px;
    margin-top: 3px;
}
.Stepper{
    width: 100%;
    min-width: 80px;
}
.NaviBar{
    background-color: #1C1C1C;
}

.spe{
    color: #ffc600;
    font-size: 60px;
}

.ProcessButton{
    margin-top: 20px;
}
.TreeListButton{
    margin-top: 13px;
}
.returnButton{
    margin-top: 9px ;
    color: white;
}
.deleteIcon{
    margin-right: 3px;
}
.cartIMG{
    width: 100px;
    height: 100px;
    margin-bottom: 5px;
    margin-left: 3px;
    margin-right: 20px;
    border-radius: 3%;
    float: left;
}
.CartQuantity{
    color: gray;
}
.CartProductName{
    font-size: 18px;
    margin-top: 13px;
}
.CartProductDescription{
    margin-top: 5px;
    color: gray;
}
.CartPrice{
    margin-top: 7px;
    margin-left: 7px;
}