.button{
    margin-left: 20px;
    margin-top: 10px;
}
.NaviBar{
    background-color: #1C1C1C;
}
.HomepagePoster{
    max-width: 100%;
    max-height: 100%;
}
.HomepageLogo{
    max-width: 60%;
    max-height: 60%;
}
.HomepagePromotionPoster{
    max-width: 100%;
    max-height: 100%;
}

.description{

}